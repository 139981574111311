import type { PaginateResult } from "mongoose"
import type { Budget } from "./budget.store"
import type { FileData } from "./file.store"
import type { Measurement } from "./measurement.store"
import type { StatusDescription } from "./status.store"
import type { GeoPoint, RiskAnalysis, DocumentDescription } from "./types"
import type { Ticket, TicketType } from "./ticket.store"
import type { AssetTag } from "./assetTag.store"
import type { RiskProfile } from "./riskSettings.store"
import type { ExternalKeyApplication } from "./externalKeyApplication.store"

type AssetComponentDataAssetRecord = Pick<
  Asset,
  "description" | "key" | "location" | "category" | "template" | "year" | "riskProfile" | "remark" | "tags"
>
export interface AssetComponentData {
  data: Array<{ _id: string; asset: AssetComponentDataAssetRecord } & Record<string, unknown>>
  fields: Record<string, Record<string, Record<string, unknown>>>
}

export enum GeneralCondition {
  UNKNOWN = "UNKNOWN",
  GOOD = "GOOD",
  DECENT = "DECENT",
  INSUFFICIENT = "INSUFFICIENT",
  BAD = "BAD",
}

export interface Location {
  city?: string
  street?: string
  houseNumber?: string
  zipCode?: string
  district?: string
  area?: string
  municipality?: string
  gps?: GeoPoint
  rd?: GeoPoint
}

export interface ExternalKey {
  _id: string
  application: Pick<ExternalKeyApplication, "_id" | "description" | "company" | "category">
  value: string
  isDefault: boolean
  lastUsedAt?: string
  lastUsedBy?: string
}

export interface GeoDataAsset {
  /** Id of the Asset */
  _id: Asset["_id"]
  /** Id of the organization, only when logged into multiple organizations */
  organization?: Asset["organization"]
  /** Combination of key | description */
  description: string
  category: Asset["category"]
  /** When available this is the main image */
  image?: string
  /** GPS coordinates*/
  gps: [longitude: number, latitude: number]
}

export interface LookupAsset
  extends Pick<Asset, "_id" | "organization" | "description" | "template" | "key" | "location" | "category" | "images" | "tags"> {
  ticketCount?: {
    inspection: number
    malfunction: number
    action: number
  }
}

export interface SearchByCoordsAsset extends Omit<LookupAsset, "ticketCount"> {
  distance: number
}

export interface SearchByTextAsset {
  _id: Asset["_id"]
  description: Asset["description"]
  key: Asset["key"]
  location: Asset["location"]
  organization: Pick<Organization, "_id" | "description">
  category: Pick<AssetCategory, "_id" | "marker" | "description">
  tags: Array<Pick<AssetTag, "_id" | "color">>
  template: Asset["template"]
}

export interface AssetComponentCondition {
  rating: GeneralCondition
  date: string
  remark?: string
  inspection?: string
}

export interface ConditionsResponse {
  group: string
  component: string
  groupDescription?: string
  componentDescription?: string
  condition: AssetComponentCondition
}

export interface AssetValue {
  _id: string
  componentProperty: string
  value: string
  lookupValue?: string
}

export interface AssetValuePayload {
  /** Property ID */
  componentProperty: string
  /** Value ID - Required if property is of type LOOKUP (dropdown values) */
  lookupValue?: string
  value: AssetValue["value"]
}

export interface AssetComponentBudget {
  interval?: number
  price?: number
  priceYear?: number
}

export type ComponentSubset = Pick<
  BaseComponent,
  "_id" | "description" | "key" | "archived" | "properties" | "inspectionProperties" | "hasGeneralCondition" | "componentType"
>

export interface AssetComponent {
  _id: string
  baseComponent: ComponentSubset
  optionalProperties?: Array<string>
  description?: string
  values?: Array<AssetValue>
  condition?: AssetComponentCondition
  files: Array<string>
  budget?: AssetComponentBudget
}

export type ComponentGroupSubset = Pick<BaseComponentGroup, "_id" | "description" | "key" | "archived" | "inspectionProperties">

export interface AssetComponentGroup {
  _id: string
  baseComponentGroup: ComponentGroupSubset
  description?: string
  components: Array<AssetComponent>
  tag?: string
}

export type AssetCategorySubset = Pick<AssetCategory, "_id" | "description" | "marker">
export type AssetTagSubset = Pick<AssetTag, "_id" | "description" | "color" | "organization">

export interface Asset {
  _id: string
  organization: string
  category: AssetCategorySubset
  template: DocumentDescription
  componentGroups: Array<AssetComponentGroup>
  description: string
  key: string
  year?: number
  remark?: string
  location: Location
  riskProfile: RiskProfile
  externalKeys: Array<ExternalKey>
  images: Array<string>
  documents: Array<string>
  tags?: Array<AssetTagSubset>
  riskScore: number
  missingRiskValues: number
}

export interface TicketCount {
  inspections: number
  malfunctions: number
  actions: number
}

export interface TimelineTicket {
  _id: string
  type: TicketType | "MUTATION"
  description: string
  date: string
  status?: StatusDescription
  property?: string
  users?: Array<{ _id?: string; description: string }>
  supplier: string
  remark?: string
  hasAccess: boolean
  transactionId?: string
}

export interface AssetBatchPayload {
  riskProfile?: RiskProfile
  category?: string
  tags?: Array<string>
}

export type YearlyCostsTicket = Pick<
  Ticket,
  | "_id"
  | "description"
  | "type"
  | "costsTotal"
  | "costsMaterial"
  | "costsLabor"
  | "laborHours"
  | "date"
  | "articles"
  | "contract"
  | "invoicePeriod"
  | "supplier"
  | "users"
>

export interface AssetBasicYearlyCosts {
  year: number
  costsTotal: number
  laborHours: number
  costsMaterial: number
  costsLabor: number
  /** The trend of the costs compared to the previous year (percentage) */
  trendTotal: number
}

export interface TicketCost {
  type: TicketType
  costsTotal: number
  laborHours: number
  costsMaterial: number
  costsLabor: number
}

export interface AssetYearlyCosts extends AssetBasicYearlyCosts {
  tickets: Array<YearlyCostsTicket>
  ticketCosts: Array<TicketCost>
}

export interface AssetBasicCostsResponse {
  yearlyCosts: Array<AssetBasicYearlyCosts>
}

export interface AssetCostsResponse {
  yearlyCosts: Array<AssetYearlyCosts>
}

export interface GenerateAssetPdfOptions {
  imageVisibility?: "HIDE_ALL" | "SHOW_MAIN" | "SHOW_ALL"
}

export interface CreateAssetPayload {
  description: Asset["description"]
  key: Asset["key"]
  remark?: Asset["remark"]
  year?: Asset["year"]
  category: string
  template: string
  tags?: Array<string>
  riskProfile?: Asset["riskProfile"]
  location: Asset["location"]
  /** Required if user is logged into multiple organizations */
  organization?: string
}

export interface UpdateAssetPayload extends Omit<Partial<CreateAssetPayload>, "organization"> {
  /** Template ID - Only allowed to be edited by super admins */
  template?: string
}

export interface CreateExternalKeyPayload {
  value: string
  application: string
}

export interface AssetConditionPayload {
  rating?: AssetComponentCondition["rating"]
  date?: string
  remark?: AssetComponentCondition["remark"]
  /** Ticket ID - inspection where the condition was set */
  inspection?: string
}

export interface AddAssetComponentGroupPayload {
  /** Base component group ID */
  baseComponentGroup: string
  description?: AssetComponentGroup["description"]
  tag?: AssetComponentGroup["tag"]
}

export type UpdateAssetComponentGroupPayload = Pick<AddAssetComponentGroupPayload, "description" | "tag">

export interface AddAssetComponentPayload {
  /** Base component ID */
  baseComponent: string
  description?: AssetComponent["description"]
  /** Property ID's - The optional properties that are visible on this component */
  optionalProperties?: Array<string>
}

export type UpdateAssetComponentPayload = Pick<AddAssetComponentPayload, "description" | "optionalProperties">

export interface ReplaceWithValue {
  componentPropertyId: string
  componentPropertyIdToReplaceWith: string
}

export interface ReplaceWithComponent {
  baseComponentId: string
  /** Ids of optional properties that should be enabled */
  optionalProperties: Array<string>
  values: Array<ReplaceWithValue>
  filesFromBaseComponent?: string
  budgetFromBaseComponent?: string
  conditionFromBaseComponent?: string
}

export interface ReplaceWithComponentGroupPayload {
  baseComponentGroupIdToReplaceWith: string
  keepCustomDescription: boolean
  components: Array<ReplaceWithComponent>
}

export const useAssetStore = defineStore("asset", () => {
  // region CRUD
  const createOne = async (payload: CreateAssetPayload): Promise<Asset> => {
    const { data } = await useSamApi<Asset>("/assets", { method: "POST", body: JSON.stringify(payload) })
    return data
  }

  const getMany = async (queryParameters: Record<string, unknown> = {}): Promise<Array<Asset>> => {
    return useCrudMethods<Asset>("/assets").readItems({ pagination: false, ...queryParameters })
  }

  const getManyByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Asset>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<Asset>>(`/assets?${query}`)
    return data
  }

  const getGeoData = async (categories?: Array<string>): Promise<Array<GeoDataAsset>> => {
    const { data } = await useSamApi<Array<GeoDataAsset>>(`/assets/geo`, {
      method: "POST",
      body: JSON.stringify({
        ...(categories && { categories }),
      }),
    })

    return data
  }

  const getManyLookup = async (queryParameters: Record<string, unknown> = {}): Promise<Array<LookupAsset>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<Array<LookupAsset>>(`/assets/lookup?${query}`)
    return data
  }

  const getOne = async (assetId: string): Promise<Asset> => {
    return useCrudMethods<Asset>("/assets").readItemById(assetId)
  }

  const patchOne = async (assetId: string, payload: UpdateAssetPayload): Promise<Asset> => {
    const { data } = await useSamApi<Asset>(`/assets/${assetId}`, { method: "PATCH", body: JSON.stringify(payload) })
    return data
  }

  const deleteOne = async (assetId: string): Promise<Asset> => {
    return useCrudMethods<Asset>("/assets").deleteItem(assetId)
  }

  // region General
  const getCount = async (queryParameters?: Record<string, unknown>): Promise<number> => {
    const searchParams = new URLSearchParams()
    if (queryParameters) {
      searchParams.append("query", JSON.stringify(queryParameters))
    }

    const { data } = await useSamApi<{ count: number }>(`/assets/count?${searchParams}`)
    return data.count
  }

  const getTotalCount = async (organization?: string) => {
    const searchParams = new URLSearchParams()
    if (organization) {
      searchParams.append("organization", organization)
    }

    const { data } = await useSamApi<number>(`/assets/total-count?${searchParams}`)
    return data
  }

  const searchByCoords = async (
    longitude: number,
    latitude: number,
    distance = 1000,
    limit?: number,
  ): Promise<Array<SearchByCoordsAsset>> => {
    const queryParameters = new URLSearchParams()
    if (limit) {
      queryParameters.append("limit", limit.toString())
    }

    const { data } = await useSamApi<Array<SearchByCoordsAsset>>(`/assets/near/${longitude}/${latitude}/${distance}?${queryParameters}`)
    return data
  }

  const searchByText = async (query: string, limit?: number): Promise<Array<SearchByTextAsset>> => {
    const { data } = await useSamApi<Array<SearchByTextAsset>>(`/assets/search?q=${query}${limit ? `&limit=${limit}` : ""}`)
    return data
  }

  const getRelationsByAssetId = async (assetId: string): Promise<Array<PopulatedAssetRelation>> => {
    try {
      const { data } = await useSamApi<Array<PopulatedAssetRelation>>(`/assets/${assetId}/relations`)
      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  const getBudgets = async (assetId: string): Promise<Array<Budget>> => {
    const { data } = await useSamApi<Array<Budget>>(`/assets/${assetId}/budgets`)
    return data
  }

  const getRiskAnalysis = async (assetId: string): Promise<RiskAnalysis> => {
    const { data } = await useSamApi<RiskAnalysis>(`/assets/${assetId}/risk-analysis`)
    return data
  }

  const getMeasurements = async (assetId: string, limit?: number): Promise<Array<Measurement>> => {
    const { data } = await useSamApi<Array<Measurement>>(`/assets/${assetId}/measurements${limit ? `?limit=${limit}` : ""}`)
    return data
  }

  const batchEditAssets = async (assetIds: Array<string>, assetData: AssetBatchPayload): Promise<Array<Asset>> => {
    const { data } = await useSamApi<Promise<Array<Asset>>>(`/assets/batch-edit`, {
      method: "POST",
      body: JSON.stringify({ assetIds, assetData }),
    })

    return data
  }

  const moveAssets = async (assetIdsToMove: Array<string>, targetOrganization: string): Promise<void> => {
    await useSamApi(`/assets/move-assets`, {
      method: "POST",
      body: JSON.stringify({
        assets: assetIdsToMove,
        organization: targetOrganization,
      }),
    })
  }

  const getAssetHistoryCount = async (organization?: string) => {
    const searchParams = getUrlSearchParams({ organization })

    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/assets/history-count?${searchParams}`)
    return data
  }

  const generateAssetPdf = async (assetId: string, options?: GenerateAssetPdfOptions) => {
    const searchParams = getUrlSearchParams(options)

    const { data: pdf } = await useSamApi<Blob>(`/assets/${assetId}/pdf?${searchParams}`, {
      headers: { Accept: "*/*" },
      method: "POST",
    })

    return URL.createObjectURL(pdf)
  }

  const getAssetDataQuality = async (id: string): Promise<AssetDataQuality | null> => {
    const { data } = await useSamApi<AssetDataQuality | null>(`/assets/${id}/data-quality`)
    return data
  }

  // region External keys
  const getExternalKeys = async (assetId: string): Promise<Array<ExternalKey>> => {
    const { data } = await useSamApi<Array<ExternalKey>>(`/assets/${assetId}/external-keys`)
    return data
  }

  const getOneByExternalKey = async (externalKey: string): Promise<Asset> => {
    const { data } = await useSamApi<Asset>(`/assets/external-key/${externalKey}`)
    return data
  }

  const getManyWithExternalKeys = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Asset>> => {
    const query = getUrlSearchParams(queryParameters)
    const { data } = await useSamApi<PaginateResult<Asset>>(`/assets/all-external-keys-assets?${query}`)
    return data
  }

  const addExternalKey = async (assetId: string, payload: CreateExternalKeyPayload): Promise<Array<ExternalKey>> => {
    const { data } = await useSamApi<Array<ExternalKey>>(`/assets/${assetId}/external-keys`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    return data
  }

  const updateExternalKey = async (assetId: string, externalKeyId: string, externalKeyValue: string): Promise<Array<ExternalKey>> => {
    const { data } = await useSamApi<Array<ExternalKey>>(`/assets/${assetId}/external-keys/${externalKeyId}`, {
      method: "PUT",
      body: JSON.stringify({ value: externalKeyValue }),
    })
    return data
  }

  const deleteExternalKey = async (assetId: string, externalKeyId: string): Promise<Array<ExternalKey>> => {
    const { data } = await useSamApi<Array<ExternalKey>>(`/assets/${assetId}/external-keys/${externalKeyId}`, { method: "DELETE" })
    return data
  }

  // region Files
  const getImages = async (assetId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/images`)
    return data
  }

  const getDocuments = async (assetId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/documents`)
    return data
  }

  const addImages = async (assetId: string, rawFiles: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    rawFiles.forEach((file) => formData.append("files", file))

    const { data } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/images`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    return data
  }

  const addDocuments = async (assetId: string, rawFiles: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    rawFiles.forEach((file) => formData.append("files", file))

    const { data } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/documents`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )

    return data
  }

  const removeImage = async (assetId: string, fileId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/images/${fileId}`, { method: "DELETE" })
    return data
  }

  const removeDocument = async (assetId: string, fileId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/documents/${fileId}`, { method: "DELETE" })
    return data
  }

  const updateImagesOrder = async (assetId: string, images: Array<string>): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/images`, { method: "PUT", body: JSON.stringify(images) })
    return data
  }

  const updateDocumentsOrder = async (assetId: string, files: Array<string>): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/documents`, { method: "PUT", body: JSON.stringify(files) })
    return data
  }

  // region Structure
  const replaceTemplate = async (assetIds: Array<string>, templateId: string): Promise<undefined> => {
    await useSamApi(`/assets/replace-component-groups`, {
      method: "POST",
      body: JSON.stringify({
        assetIds,
        templateId,
      }),
    })
  }

  const getComponentGroups = async (assetId: string): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/assets/${assetId}/groups`)
    return data
  }

  const updateComponentGroupsOrder = async (
    assetId: string,
    reorderedAssetComponentGroupIds: Array<string>,
  ): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/assets/${assetId}/groups`, {
      method: "PUT",
      body: JSON.stringify(reorderedAssetComponentGroupIds),
    })

    return data
  }

  const updateComponentsOrder = async (
    assetId: string,
    assetComponentGroupId: string,
    reorderedAssetComponentIds: Array<string>,
  ): Promise<Array<AssetComponent>> => {
    const { data } = await useSamApi<Array<AssetComponent>>(`/assets/${assetId}/groups/${assetComponentGroupId}/components`, {
      method: "PUT",
      body: JSON.stringify(reorderedAssetComponentIds),
    })

    return data
  }

  const updateComponentBudget = async (
    assetId: string,
    assetComponentGroupId: string,
    assetComponentId: string,
    payload: AssetComponentBudget,
  ): Promise<AssetComponent> => {
    const { data } = await useSamApi<AssetComponent>(
      `/assets/${assetId}/groups/${assetComponentGroupId}/components/${assetComponentId}/budget`,
      {
        method: "PUT",
        body: JSON.stringify(payload),
      },
    )

    return data
  }

  const updateComponentValues = async (
    id: string,
    assetComponentGroupId: string,
    assetComponentId: string,
    valuesPayload: Array<AssetValuePayload>,
  ): Promise<Array<AssetValue>> => {
    const { data } = await useSamApi<Array<AssetValue>>(
      `/assets/${id}/groups/${assetComponentGroupId}/components/${assetComponentId}/values`,
      {
        method: "PUT",
        body: JSON.stringify(valuesPayload),
      },
    )

    return data
  }

  /** Get the files that are coupled to values of a asset's component */
  const getCoupledComponentFiles = async (
    assetId: string,
    assetComponentGroupId: string,
    assetComponentId: string,
  ): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/groups/${assetComponentGroupId}/components/${assetComponentId}/coupled-files`,
    )
    return data
  }

  const addComponentFiles = async (
    assetId: string,
    assetComponentGroupId: string,
    assetComponentId: string,
    rawFiles: Array<File>,
  ): Promise<Array<FileData>> => {
    const formData = new FormData()
    rawFiles.forEach((file) => formData.append("files", file))

    const { data } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/groups/${assetComponentGroupId}/components/${assetComponentId}/files`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    return data
  }

  const removeComponentFile = async (
    assetId: string,
    assetComponentGroupId: string,
    assetComponentId: string,
    fileId: string,
  ): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/groups/${assetComponentGroupId}/components/${assetComponentId}/files/${fileId}`,
      {
        method: "DELETE",
      },
    )
    return data
  }

  const updateCondition = async (
    assetId: string,
    assetComponentGroupId: string,
    assetComponentId: string,
    payload: AssetConditionPayload,
  ): Promise<AssetComponent> => {
    const { data } = await useSamApi<AssetComponent>(
      `/assets/${assetId}/groups/${assetComponentGroupId}/components/${assetComponentId}/condition`,
      {
        method: "PUT",
        body: JSON.stringify(payload),
      },
    )

    return data
  }

  const addAssetComponentGroup = async (assetId: string, payload: AddAssetComponentGroupPayload): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/assets/${assetId}/groups`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    return data
  }

  const removeAssetComponentGroup = async (assetId: string, assetComponentGroupId: string): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/assets/${assetId}/groups/${assetComponentGroupId}`, { method: "DELETE" })
    return data
  }

  const updateAssetComponentGroup = async (
    assetId: string,
    assetComponentGroupId: string,
    payload: UpdateAssetComponentGroupPayload,
  ): Promise<AssetComponentGroup> => {
    const { data } = await useSamApi<AssetComponentGroup>(`/assets/${assetId}/groups/${assetComponentGroupId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })

    return data
  }

  const addAssetComponent = async (
    assetId: string,
    assetComponentGroupId: string,
    payload: AddAssetComponentPayload,
  ): Promise<Array<AssetComponent>> => {
    const { data } = await useSamApi<Array<AssetComponent>>(`/assets/${assetId}/groups/${assetComponentGroupId}/components`, {
      method: "POST",
      body: JSON.stringify(payload),
    })

    return data
  }

  const updateAssetComponent = async (
    assetId: string,
    assetComponentGroupId: string,
    assetComponentId: string,
    payload: UpdateAssetComponentPayload,
  ): Promise<AssetComponent> => {
    const { data } = await useSamApi<AssetComponent>(`/assets/${assetId}/groups/${assetComponentGroupId}/components/${assetComponentId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })

    return data
  }

  const removeAssetComponent = async (
    assetId: string,
    assetComponentGroupId: string,
    assetComponentId: string,
  ): Promise<Array<AssetComponent>> => {
    const { data } = await useSamApi<Array<AssetComponent>>(
      `/assets/${assetId}/groups/${assetComponentGroupId}/components/${assetComponentId}`,
      {
        method: "DELETE",
      },
    )

    return data
  }

  const replaceAssetComponentGroup = async (
    assetId: string,
    assetComponentGroupId: string,
    payload: ReplaceWithComponentGroupPayload,
  ): Promise<AssetComponentGroup> => {
    const { data } = await useSamApi<AssetComponentGroup>(`/assets/${assetId}/groups/${assetComponentGroupId}/replace`, {
      method: "POST",
      body: JSON.stringify(payload),
    })

    return data
  }

  // region Tickets
  const getTicketCount = async (assetId: string, ...statuses: Array<string>): Promise<TicketCount> => {
    const params = new URLSearchParams()
    statuses.forEach((status) => params.append("status", status))

    const { data } = await useSamApi<TicketCount>(`/assets/${assetId}/ticket-count?${params}`)
    return data
  }

  const getTimelineTickets = async (assetId: string, mutations: boolean): Promise<Array<TimelineTicket>> => {
    const { data } = await useSamApi<Array<TimelineTicket>>(`/assets/${assetId}/timeline?mutations=${mutations}`)
    return data
  }

  const getBasicAssetCosts = async (
    assetId: string,
    options?: { fromYear?: number; toYear?: number },
  ): Promise<AssetBasicCostsResponse> => {
    const searchParams = getUrlSearchParams(options)

    const { data } = await useSamApi<AssetBasicCostsResponse>(`/assets/${assetId}/costs/basic?${searchParams}`)
    return data
  }

  const getDetailedAssetCosts = async (assetId: string, options?: { fromYear?: number; toYear?: number }): Promise<AssetCostsResponse> => {
    const searchParams = getUrlSearchParams(options)

    const { data } = await useSamApi<AssetCostsResponse>(`/assets/${assetId}/costs/detailed?${searchParams}`)
    return data
  }

  // region Dev Express
  /** @deprecated This is for the Dev Express tables that still exist */
  const getAssetsComponents = async (componentTypes?: string | Array<string>): Promise<AssetComponentData> => {
    const queryParameters = new URLSearchParams()
    if (componentTypes) {
      if (Array.isArray(componentTypes)) {
        componentTypes.forEach((type) => queryParameters.append("type", type))
      } else {
        queryParameters.append("type", componentTypes)
      }
    }

    const { data } = await useSamApi<AssetComponentData>(`/assets/components?${queryParameters}`)
    return data
  }

  return {
    // CRUD
    createOne,
    getMany,
    getManyByPage,
    getGeoData,
    getManyLookup,
    getOne,
    patchOne,
    deleteOne,

    // General
    searchByCoords,
    getRelationsByAssetId,
    getMeasurements,
    getBudgets,
    getRiskAnalysis,
    searchByText,
    getCount,
    getTotalCount,
    getAssetHistoryCount,
    batchEditAssets,
    moveAssets,
    replaceTemplate,
    generateAssetPdf,
    getAssetDataQuality,

    // Files
    getImages,
    getDocuments,
    addImages,
    addDocuments,
    removeImage,
    removeDocument,
    updateImagesOrder,
    updateDocumentsOrder,

    // Structure
    getComponentGroups,
    getCoupledComponentFiles,
    addAssetComponentGroup,
    addAssetComponent,
    addComponentFiles,
    updateComponentGroupsOrder,
    updateComponentsOrder,
    updateAssetComponentGroup,
    updateAssetComponent,
    updateComponentBudget,
    updateComponentValues,
    updateCondition,
    removeComponentFile,
    removeAssetComponentGroup,
    removeAssetComponent,
    replaceAssetComponentGroup,

    // External keys
    getManyWithExternalKeys,
    getExternalKeys,
    addExternalKey,
    updateExternalKey,
    deleteExternalKey,
    getOneByExternalKey,

    // Tickets
    getTicketCount,
    getTimelineTickets,
    getBasicAssetCosts,
    getDetailedAssetCosts,

    // Dev Express
    getAssetsComponents,
  }
})
