import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_ALLXFGgbCP from "/app/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_client_pNVVgm7Ud2 from "/app/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/app/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/app/.nuxt/vuefire-plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import dayjs_pGiXRjcsJO from "/app/plugins/dayjs.ts";
import maps_kdC331bDMv from "/app/plugins/maps.ts";
import scrollToTop_qhSEFOqbwG from "/app/plugins/scrollToTop.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
import ssg_detect_3fHkBxLtv0 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_qLmFnukI99,
  payload_plugin_ALLXFGgbCP,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_client_pNVVgm7Ud2,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  dayjs_pGiXRjcsJO,
  maps_kdC331bDMv,
  scrollToTop_qhSEFOqbwG,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH,
  ssg_detect_3fHkBxLtv0
]