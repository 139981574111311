import * as Sentry from "@sentry/vue"
import type { AuthScope } from "./auth.store"
import type { Organization } from "./organization.store"
import type { PaginateResult } from "mongoose"
import type { HistoryDataPoint } from "./types"

export enum UserRole {
  /** A supplier mechanic may have access to multiple organizations, but with set permissions from the organization it logs into */
  SUPPLIER_MECHANIC = "SUPPLIER_MECHANIC",
  /** A supplier planner may have access to multiple organizations, but with set permissions from the organization it logs into */
  SUPPLIER_PLANNER = "SUPPLIER_PLANNER",
  /** A supplier manager may have access to multiple organizations, but with set permissions from the organization it logs into */
  SUPPLIER_MANAGER = "SUPPLIER_MANAGER",
  //** Super user account */
  SUPER_ADMIN = "SUPER_ADMIN",
  //** Default - Normal user account */
  USER = "USER",
}

export enum InfoCardType {
  ASSETS = "assets",
  TICKETS = "tickets",
  MALFUNCTIONS = "malfunctions",
  ACTIONS = "actions",
  INSPECTIONS = "inspections",
  RISKY_ASSETS = "risky-assets",
  FINANCES = "finances",
}

export type SupplierRole = UserRole.SUPPLIER_MECHANIC | UserRole.SUPPLIER_PLANNER | UserRole.SUPPLIER_MANAGER

export interface AllowedStatuses {
  actions: Array<AuthScope>
  inspections: Array<AuthScope>
  malfunctions: Array<AuthScope>
}

export interface OrganizationLogin {
  organization: Organization["_id"]
  date: string
}

export interface User {
  _id: string
  /** @deprecated Email is used for logging in */
  username?: string
  userRole: UserRole
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  hasAccess: boolean
  organization: string
  lastLogins: Array<OrganizationLogin>
  lastReleaseNoteRead?: string
  lastLoginAttempts?: Array<Date>
  createdAt?: string
  updatedAt?: string
}

export type BasicUser = Pick<User, "_id" | "firstName" | "lastName" | "email" | "hasAccess" | "userRole" | "organization"> & {
  description: string
}

export interface UserCreatePayload {
  userRole: UserRole
  email: string
  firstName: string
  lastName: string
  hasAccess: boolean
  organization: string
  phone?: string
}

export type UserUpdatePayload = Partial<UserCreatePayload>

export interface UserUpdateSelfPayload {
  phone?: string
  lastReleaseNoteRead?: string | Date
}

export const useUserStore = defineStore("user", () => {
  const currentUser = ref<User>()

  const getUsers = (pagination = true, organization?: string) =>
    useCrudMethods<User>("/users").readItems(organization ? { pagination, organization } : { pagination })
  const getUsersByPage = async (queryParameters: Record<string, unknown>) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<User>>(`/users?${query}`)
    return data
  }
  const getUserById = (id: User["_id"]) => useCrudMethods<User>("/users").readItemById(id)

  const deleteOne = async (userId: User["_id"]): Promise<User> => {
    const { data } = await useSamApi<User>(`/users/${userId}`, { method: "DELETE" })
    return data
  }

  const createOne = async (payload: UserCreatePayload): Promise<User> => {
    const { data } = await useSamApi<User>("/users", { method: "POST", body: JSON.stringify(payload) })
    return data
  }

  /** Update a user (super admin only) */
  const updateOne = async (userId: User["_id"], payload: UserUpdatePayload): Promise<User> => {
    const { data } = await useSamApi<User>(`/users/${userId}`, { method: "PATCH", body: JSON.stringify(payload) })
    return data
  }

  const updateSelf = async (payload: UserUpdateSelfPayload) => {
    const { data } = await useSamApi<User>("/users", { method: "PATCH", body: JSON.stringify(payload) })

    currentUser.value!.phone = data.phone
    currentUser.value!.lastReleaseNoteRead = data.lastReleaseNoteRead

    return data
  }

  const getSelf = async (forceWriteLoginEvent = false) => {
    const { data } = await useSamApi<User>("/users/info")
    currentUser.value = data

    Sentry.setUser({
      id: data._id,
      email: data.email,
    })

    trackLoggedInSession(forceWriteLoginEvent)

    return data
  }

  const trackLoggedInSession = async (forceWriteLoginEvent = false): Promise<void> => {
    if (!currentUser.value) {
      // User not logged in yet
      return
    }

    const isSessionLogged = useSessionStorage<boolean>("loggedSession", false)

    // If we are forcing the write of the login event, we don't need to skip
    if (!forceWriteLoginEvent) {
      // Check if session is already logged
      if (isSessionLogged.value) {
        return
      }

      const runtimeConfig = useRuntimeConfig()

      // Redirected from SAM page (new TAB) - ignore
      if (document.referrer.includes(runtimeConfig.public.BASE_URL)) {
        return
      }
    }

    const pwa = window.matchMedia("(display-mode: standalone)").matches
    await useSamApi<string>("/auth/logged-in-session", { method: "POST", body: JSON.stringify({ pwa }) })

    isSessionLogged.value = true
  }

  const getAssignableUsers = async (organization?: string): Promise<Array<BasicUser>> => {
    const { data } = await useSamApi<Array<BasicUser>>(`/users/lookup?${organization ? new URLSearchParams({ organization }) : ""}`)
    return data
  }

  const getUserCount = async (organization?: string, createdFrom?: Date) => {
    const searchParams = new URLSearchParams()
    if (organization) {
      searchParams.append("organization", organization)
    }
    if (createdFrom) {
      searchParams.append("createdFrom", createdFrom.toISOString())
    }

    const { data } = await useSamApi<{ count: number }>(`/users/count?${searchParams}`)
    return data.count
  }

  const getUserHistoryCount = async (organization?: string, query?: Record<string, unknown>) => {
    const queryParameters = getUrlSearchParams({ organization, ...query })
    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/users/history-count?${queryParameters}`)

    return data
  }

  const activateUser = async (userId: User["_id"]) => {
    const { data } = await useSamApi<User>(`/users/${userId}/activate`, { method: "POST" })
    return data
  }

  const deactivateUser = async (userId: User["_id"]) => {
    const { data } = await useSamApi<User>(`/users/${userId}/deactivate`, { method: "POST" })
    return data
  }

  const fullName = computed(() => `${currentUser.value?.firstName} ${currentUser.value?.lastName}`.trim())

  return {
    currentUser,
    fullName,

    getUserById,
    getUsers,
    getUsersByPage,

    updateOne,
    createOne,
    deleteOne,

    getCurrentUser: getSelf,
    updateSelf,

    getUserCount,
    getUserHistoryCount,

    getAssignableUsers,

    activateUser,
    deactivateUser,
    trackLoggedInSession,
  }
})
