export const modulesPerOrganization = ref<Record<string, Required<OrganizationSettings>["modules"]>>({})

export const useSettingsStore = defineStore("settings", () => {
  const authStore = useAuthStore()
  const { currentOrganization, isSuperAdmin, isSupplier, sourceOrganization } = storeToRefs(authStore)

  // Load organization modules if not already loaded
  if (currentOrganization.value && modulesPerOrganization.value[currentOrganization.value] === undefined) {
    const organizationStore = useOrganizationStore()

    organizationStore.getSettings(currentOrganization.value).then((settings) => {
      modulesPerOrganization.value[currentOrganization.value!] = settings.modules ?? {}
    })
  }

  if (isSupplier.value && sourceOrganization.value && modulesPerOrganization.value[sourceOrganization.value] === undefined) {
    const organizationStore = useOrganizationStore()

    organizationStore.getSettings(sourceOrganization.value).then((settings) => {
      modulesPerOrganization.value[sourceOrganization.value!] = settings.modules ?? {}
    })
  }

  const isInAdminPanel = computed(() => {
    return !currentOrganization.value && isSuperAdmin.value
  })

  const ssoModuleEnabled = computed(() => {
    if (isInAdminPanel.value) {
      return true
    }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.sso
  })

  const ticketFinanceModuleEnabled = computed(() => {
    if (isInAdminPanel.value) {
      return true
    }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.ticketFinance
  })

  const dataQualityModuleEnabled = computed(() => {
    if (isInAdminPanel.value) {
      return true
    }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.dataQuality
  })

  const supplierModuleEnabled = computed(() => {
    if (isInAdminPanel.value) {
      return true
    }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.supplier
  })

  const sourceSupplierModuleEnabled = computed(() => {
    return !!sourceOrganization.value && !!modulesPerOrganization.value[sourceOrganization.value]?.supplier && isSupplier.value
  })

  const subSupplierModuleEnabled = computed(() => {
    if (isInAdminPanel.value) {
      return true
    }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.subSupplier
  })

  const isChristmasDecorationEnabled = computed(() => {
    return new Date().getMonth() === 11
  })

  const isNenEnabled = computed(() => {
    if (!currentOrganization.value) {
      return false
    }

    return [
      "5d1c9199581a5c125e60a9d3", // Heusden
      "5d1c9199581a5c125e60a99c", // Almere
      "5d1c9199581a5c125e60a9ac", // Gemeente Bodegraven-Reeuwijk
    ].includes(currentOrganization.value)
  })

  return {
    ssoModuleEnabled,
    ticketFinanceModuleEnabled,
    dataQualityModuleEnabled,
    supplierModuleEnabled,
    /** Checks if the user is supplier and the supplier has the supplier module enabled */
    sourceSupplierModuleEnabled,
    subSupplierModuleEnabled,
    isChristmasDecorationEnabled,
    /* Temporary enabled for specific customers **/
    isNenEnabled,
  }
})
