export interface SnackbarAction {
  label: string
  handler: () => void
}

export interface Snackbar {
  title: string
  text?: string
  type: "success" | "error" | "warning" | "info"
  timeout?: number
  actions?: Array<SnackbarAction>
  key?: string
  closable?: boolean
  pending?: boolean
  /** How many times this message was generated */
  count?: number
}

const snackbars = ref<Array<Snackbar>>([])

export const useMainStore = defineStore("main", () => {
  const notify = (snackbar: Snackbar): Snackbar => {
    let duplicate: Snackbar | undefined

    if (snackbar.text) {
      // Count duplicate snackbars with same title and text
      duplicate = snackbars.value
        .filter((message) => message.text)
        .find((message) => message.title === snackbar.title && message.text === snackbar.text)
    } else {
      // Count duplicate snackbars with same title
      duplicate = snackbars.value.find((message) => message.title === snackbar.title)
    }

    if (duplicate) {
      duplicate.count = (duplicate.count ?? 1) + 1
      return duplicate
    }
    snackbars.value.push({ ...snackbar, key: Math.random().toString() })
    return snackbars.value[snackbars.value.length - 1]!
  }

  return {
    snackbars,
    notify,
  }
})
