/** Map visibility on pages */
export enum MapVisibility {
  ASSETS = "ASSETS",
  ASSET_DETAILS = "ASSET_DETAILS",
  TICKETS = "TICKETS",
}

export enum CookiePreferences {
  FUNCTIONAL = "FUNCTIONAL",
  ANALYTICS = "ANALYTICS",
  TRACKING = "TRACKING",
}

export const useUserPreferences = () => {
  const mapVisibility = useLocalStorage<Array<MapVisibility>>("sam-user-preference::mapVisibility", [MapVisibility.ASSETS], { deep: true })
  const thumbnailsBig = useLocalStorage<boolean>("sam-user-preference::thumbnailsBig", false)
  const miniDrawer = useLocalStorage<boolean>("sam-user-preference::miniDrawer", false)
  const theme = useLocalStorage<string | null>("sam-user-preference::theme", null)
  const preferRadio = useLocalStorage<boolean>("sam-user-preference::preferRadio", false)
  const preferLarge = useLocalStorage<boolean>("sam-user-preference::preferLarge", false)
  const showPreviousInspectionData = useLocalStorage<boolean>("sam-user-preference::showPreviousInspectionData", false)
  const geolocationEnabled = useLocalStorage<boolean>("sam-user-preference::geolocationEnabled", false)
  const infoCardSelection = useLocalStorage<Array<InfoCardType>>("sam-user-preference::infoCardSelection", [], { deep: true })
  const cookiePreferences = useLocalStorage<Array<string>>("sam-user-preference::cookiePreferences", [], { deep: true })
  const useGoogleMaps = useLocalStorage<boolean>("sam-user-preference::useGoogleMaps", false)
  const useOpenLayers = useLocalStorage<boolean>("sam-user-preference::useOpenLayers", false)
  const showAssetWidgets = useLocalStorage<boolean>("sam-user-preference::showAssetWidgets", true)
  const showAssetComponentIcons = useLocalStorage<boolean>("sam-user-preference::showAssetComponentIcons", false)

  return {
    /** Determines if the map drawer is default open on specific pages */
    mapVisibility,
    /** Determines if the thumbnails of files are big or small */
    thumbnailsBig,
    /** Determines if the drawer is mini or normal */
    miniDrawer,
    /** Determines the theme of the application */
    theme,
    /** Determines if the radio button is preferred over the checkbox in inspection forms */
    preferRadio,
    /** Determines if the large input fields are preferred over the small ones in inspection forms */
    preferLarge,
    /** Determines if the previous inspection data is shown in the inspection form */
    showPreviousInspectionData,
    /** Determines if the geolocation is allowed to be used */
    geolocationEnabled,
    /** Determines the info cards that are shown in the dashboard */
    infoCardSelection,
    /** Determines the cookie preferences of the user */
    cookiePreferences,
    /** Determines if the Google Maps is used instead of the OpenStreetMap */
    useGoogleMaps,
    /** Determines if the OpenLayers is used instead of the Leaflet */
    useOpenLayers,
    /** Determines if the widgets of the assets are shown */
    showAssetWidgets,
    /** Determines if the icons of the asset components are shown on passport */
    showAssetComponentIcons,
  }
}
